<template>
  <div class="progress-wrapper">
    <div class="progress-card">
      <div class="progress-card__title">
        <div class="progress-card__title-text">功率</div>
        <div class="progress-card__title-icon">
          <inline-svg :src="require('../../assets/icon/svg/charging-kW.svg')" />
        </div>
      </div>
      <div class="progress-card__body">
        <h4 class="progress-card__body-title">
          <span>{{ this.progress.chargingPower | toFixed }}</span
          ><small>kW</small>
        </h4>
      </div>
    </div>
    <div class="progress-card">
      <div class="progress-card__title">
        <div class="progress-card__title-text">持續時間</div>
        <div class="progress-card__title-icon">
          <inline-svg
            :src="require('../../assets/icon/svg/charging-time.svg')"
          />
        </div>
      </div>
      <div class="progress-card__body">
        <h4 class="progress-card__body-title">
          <span>{{ this.progress.duration | totalTime }}</span>
          <small>分</small>
        </h4>
      </div>
    </div>
    <div class="progress-card">
      <div class="progress-card__title">
        <div class="progress-card__title-text">充電電量</div>
        <div class="progress-card__title-icon">
          <inline-svg
            :src="require('../../assets/icon/svg/charging-kWh.svg')"
          />
        </div>
      </div>
      <div class="progress-card__body">
        <h4 class="progress-card__body-title">
          <span> {{ this.progress.energy | toFixed }}</span
          ><small>kWh</small>
        </h4>
      </div>
    </div>
    <div class="progress-card">
      <div class="progress-card__title">
        <div class="progress-card__title-text">累計金額</div>
        <div class="progress-card__title-icon">
          <inline-svg
            :src="require('../../assets/icon/svg/charging-dollar.svg')"
          />
        </div>
      </div>
      <div class="progress-card__body">
        <h4 class="progress-card__body-title">
          <span>{{ this.progress.amount | toFixedInteger }}</span
          ><small>元</small>
        </h4>
      </div>
    </div>
  </div>
</template>
<script>
import { timeFilter, powerFilter } from "../../utils/mixins";
export default {
  name: "ProgressCards",
  mixins: [timeFilter, powerFilter],
  props: {
    progress: {
      type: Object,
      required: true,
    },
  },
  watch: {
    progress: function (newVal) {
      this.progress = newVal;
    },
  },
};
</script>
