<template>
  <div class="modal-dialog is-top is-rounded">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-header__title">停止充電</h1>
      </div>
      <div class="modal-body">
        <p>停止充電請按確認。 若希望繼續充電狀態，請按返回。</p>
      </div>
      <div class="modal-footer">
        <button
          @click="stopCharging()"
          type="button"
          class="button is-fullwidth is-danger-fill mb-2"
          :disabled="isProcessing"
        >
          {{ isProcessing ? "停止中" : "確認停止" }}
        </button>
        <button
          type="button"
          class="button is-fullwidth is-normal"
          @click="handleModal()"
        >
          返回
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "StopChargingModal",
  data() {
    return {
      isProcessing: false,
    };
  },
  methods: {
    handleModal() {
      this.$emit("close-modal");
    },
    stopCharging() {
      this.isProcessing = true;
      this.$emit("stop-charging");
      this.isProcessing = false;
    },
  },
};
</script>
