import { apiHelper } from "../utils/helpers";

export default {
  charging: {
    postGuest(guestData) {
      return apiHelper.post("/charging/guest", guestData);
    },
    startCharging({ chargingid }) {
      return apiHelper.post(`/charging/guest/start?chargingid=${chargingid}`);
    },
    getChargingProgress({ chargingid }) {
      return apiHelper.get(`/charging/guest/progress?chargingid=${chargingid}`);
    },
    stopCharging({ chargingid, connectorId }) {
      return apiHelper.post(
        `/charging/guest/stop?chargingid=${chargingid}`,
        connectorId
      );
    },
    getChargingRecord({ chargingid }) {
      return apiHelper.get(`/charging/guest?chargingid=${chargingid}`);
    },
    getUnitPrice({ stationid }) {
      return apiHelper.get(`/charging/guest/pricing?stationid=${stationid}`);
    },
    sendEmail({ chargingid }) {
      return apiHelper.post(`/charging/guest/receipt?chargingid=${chargingid}`);
    },
  },
};
