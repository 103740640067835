<template>
  <main id="charge">
    <section class="section-top pb-1-5">
      <div class="spec-box">
        <div class="spec-item__text">
          <p class="spec-item__text--percentage">
            {{ isLoading ? "" : this.progress.percentage }}
            <small>{{ isLoading ? "" : "%" }}</small>
          </p>
        </div>
      </div>
      <h1 class="section-top-title">充電進度</h1>
    </section>
    <section class="section-main py-0" v-if="isStopping || isLoading">
      <div class="section-main__content stopping">
        <inline-svg
          :src="require('../assets/icon/svg/loading.svg')"
          class="loading"
        />
        <div class="section-main__title">
          <h1>充電裝置正在{{ isStopping ? "停止中" : "連線中" }}</h1>
        </div>
        <p>請稍等幾秒鐘</p>
      </div>
    </section>
    <section class="section-main py-0" v-else>
      <div class="section-main__content">
        <ProgressCards :progress="progress" />
        <div
          class="section-main__action"
          v-if="this.progress.finished !== this.finished.true"
        >
          <button
            type="button"
            class="button is-fullwidth is-link is-rounded"
            @click="handleModal()"
          >
            停止充電
          </button>
        </div>
        <div class="section-main__hint">
          <small>
            以上數據非即時顯示，僅供參考。請注意：最終費用取決於實際充電的時數，實際費用待充電結束後收取。
          </small>
        </div>
      </div>
    </section>
    <div class="modal" :class="{ active: isModalOpen }">
      <StopChargingModal
        is-processing="isProcessing"
        @close-modal="handleModal"
        @stop-charging="stop"
      />
    </div>
  </main>
</template>

<script>
import chargingAPI from "../apis/charging";
import { mapState } from "vuex";
import StopChargingModal from "../components/Progress/StopChargingModal.vue";
import ProgressCards from "../components/Progress/ProgressCards.vue";

export default {
  name: "Progress",
  data() {
    return {
      timer: null,
      isModalOpen: false,
      progress: {
        chargingId: "",
        startTime: "",
        startPercentage: 0,
        percentage: 0,
        chargingPower: 0,
        maxPower: 0,
        averagePower: 0,
        energy: 0,
        duration: 0,
        amount: 0,
        finished: 0,
        createdTime: "",
      },
      finished: {
        false: 0,
        true: 1,
      },
      isStopping: false,
      isLoading: true,
    };
  },
  mounted() {
    this.timer = setInterval(this.start, 5 * 1000);
    this.start();
  },
  components: { StopChargingModal, ProgressCards },
  methods: {
    handleModal() {
      this.isModalOpen = !this.isModalOpen;
    },
    start() {
      const { chargingId } = this.$route.params;
      this.getProgress(chargingId);
    },
    async getProgress(chargingid) {
      try {
        const { data, status } = await chargingAPI.charging.getChargingProgress(
          {
            chargingid,
          }
        );
        const { chargingId, stationId, connectorId } = this.$route.params;
        if (!(status >= 200 && status < 300)) {
          this.$router.replace({
            name: "ProgressErrorHint",
            params: {
              chargingId: chargingId,
              stationId: stationId,
              connectorId: connectorId,
            },
          });
          throw new Error(status);
        }
        if (status === 200) {
          this.isLoading = false;
        }
        this.progress = data;
        if (this.progress.finished === this.finished.true) {
          clearInterval(this.timer);
          this.timer = null;
          this.$router.replace({
            name: "Charging-receipt",
            params: { chargingId: chargingId },
          });
        }
      } catch (error) {
        console.log(error);
        const { chargingId, stationId, connectorId } = this.$route.params;
        this.$router.replace({
          name: "ProgressErrorHint",
          params: {
            chargingId: chargingId,
            stationId: stationId,
            connectorId: connectorId,
          },
        });
      }
    },
    stop() {
      const payload = {
        ConnectorId: this.$route.params.connectorId,
      };
      this.stopCharging(payload);
    },
    async stopCharging(connectorId) {
      try {
        const { chargingId, stationId } = this.$route.params;
        const { status } = await chargingAPI.charging.stopCharging({
          chargingid: this.$route.params.chargingId,
          connectorId,
        });
        if (!(status >= 200 && status < 300)) {
          this.$router.replace({
            name: "StopErrorHint",
            params: {
              chargingId: chargingId,
              stationId: stationId,
              connectorId: connectorId,
            },
          });
          throw new Error(status);
        }

        this.isModalOpen = false;
        this.isStopping = true;
      } catch (error) {
        const { chargingId, stationId, connectorId } = this.$route.params;
        this.$router.replace({
          name: "StopErrorHint",
          params: {
            chargingId: chargingId,
            stationId: stationId,
            connectorId: connectorId,
          },
        });
        console.log(error);
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  computed: {
    ...mapState(["connector"]),
  },
};
</script>
